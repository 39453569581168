import React, { useEffect, useContext, useRef } from "react";
import classNames from "classnames";

import { REQUEST_STATUS } from "app/constants";

import {
  generateUrlFromFilters,
  getSelectedFilterParams,
  getSelectedRegionName,
  getFilterRequestParamsFromLocation,
  getContextRequestParamsFromFilters,
  declOfNum,
} from "utils";
import {
  isWidgetMode,
  onScrollTo,
  widgetConfig,
  widgetConfigPromise,
  isIOS,
  parentScrollPosition,
  scrollWindow,
} from "utils/frameService";
import {
  getDefaultDomainFilters,
  getDefaultExpressionsAndRegions,
} from "app/utils/filtersService";
import { useWindowSize } from "app/utils/windowSizeHook";

import { getPopularAndSelectedRegions, RegionsContext } from "context/Regions";
import { CompareContext } from "context/Compare";
import { loadOffersList, OffersListContext } from "app/context/OffersList";
import { FiltersContext, getFiltersData } from "app/context/Filters";
import { SavedFiltersContext } from "app/context/SavedFilters";

import Filters from "components/shared/Filters";
import Footer from "components/Footer";
import Header from "components/Header";
import Loading from "components/Loading";
import MobileFiltersLabel from "components/shared/MobileFiltersLabel";
import Sorting from "components/OffersListPage/Sorting";
import Offer from "components/OffersListPage/Offer";
import Results from "components/OffersListPage/Results";
import Seo from "components/Seo";
import LinkButton from "components/ui/LinkButton";

import "./styles.less";
import { getLocalText, getLocalTextWithNumbers } from "app/utils/i18nService";
import { useUpdateEffect } from "app/utils/useUpdateEffect";

let offersResults = null;
let regionWasChangedInFilterPopup = false;
let filterRef = null;
let prevFilterSet = "ALL";
let fetchMore = false;

const OffersListPage = props => {
  const { regions, radius, selectedRegions } = useContext(RegionsContext);
  const { functions: savedFiltersContextFunctions } = useContext(
    SavedFiltersContext,
  );
  const {
    offersList: offersListFilters,
    isPopupShown,
    functions: filtersContextFunctions,
  } = useContext(FiltersContext);

  const {
    endCursor,
    offers,
    results,
    sorting,
    status,
    functions: offersListContextFunctions,
  } = useContext(OffersListContext);
  const { carsIdList, functions: compareContextFunctions } = useContext(
    CompareContext,
  );
  const { isMobileWidth } = useWindowSize();

  useEffect(() => {
    scrollWindow(0);

    if (status !== "SUCCESS") {
      updateOffersFromLocation();
    }
    window.addEventListener("popstate", updateOffersFromLocation);

    //componentWillUnmount
    return () => {
      window.removeEventListener("popstate", updateOffersFromLocation);
      offersListContextFunctions.resetStatus();
      saveFilter();
    };
  }, []);

  const filtersRef = useRef(offersListFilters.filters);
  useEffect(() => {
    filtersRef.current = offersListFilters.filters;
  }, [offersListFilters.filters]);

  const locationRef = useRef(props.location);
  useEffect(() => {
    locationRef.current = props.location;
  }, [props.location]);

  useEffect(() => {
    if (isWidgetMode && fetchMore) {
      onScrollTo(
        isMobileWidth ? parentScrollPosition : parentScrollPosition + 77,
        false,
        true,
        true,
      );
      fetchMore = false;
    }
  }, [offers.length]);

  useEffect(() => {
    if (isWidgetMode && isMobileWidth && isPopupShown) {
      if (offersListFilters.filterSet === "BASIC") {
        if (isIOS) {
          scrollWindow(0);
        }
        onScrollTo(document.documentElement.scrollHeight - window.innerHeight);
      } else {
        onScrollTo(parentScrollPosition, false, true, true);
      }
    }
  }, [offersListFilters.filterSet]);

  useUpdateEffect(() => {
    if (isWidgetMode && isMobileWidth) {
      onScrollTo(0);
    }
  }, [isPopupShown]);

  useUpdateEffect(() => {
    onRegionChange();
  }, [radius, selectedRegions]);

  useUpdateEffect(() => {
    setBrowserLocationPath();
  }, [offers]);

  const saveFilter = () => {
    const isFilterSelect = filtersRef.current.some(filter => filter.selected);
    if (isFilterSelect) {
      savedFiltersContextFunctions.saveFilter(
        locationRef.current.pathname + locationRef.current.search,
      );
    }
  };

  const updateOffersFromLocation = async () => {
    const { location } = props;

    let offersParams = getFilterRequestParamsFromLocation(
      location,
      regions,
      selectedRegions,
      radius,
    );
    if (isWidgetMode) {
      if (widgetConfigPromise) {
        await widgetConfigPromise;
      }

      const defaultExpressions = getDefaultExpressionsAndRegions(
        offersParams.expressions,
      );
      offersParams.expressions = defaultExpressions.expressions;
      offersParams.domain = getDefaultDomainFilters();

      if (
        defaultExpressions.expressionsList.some(
          expression => expression.indexOf("1101=") > -1,
        )
      ) {
        offersParams.resourcePath = null;
      }

      if (widgetConfig.filtersList.showAllFilters) {
        offersParams.filterSet = "ALL";
      }
    }
    offersParams.filterSet = offersListFilters.filterSet;
    offersListContextFunctions.getOffersList(
      offersParams,
      filtersContextFunctions.setFiltersData,
    );
  };

  const loadOffers = (loadOffersParams, loadOffersInMobile = false) => {
    const defaultExpressions = getDefaultExpressionsAndRegions(
      loadOffersParams.expressions,
    );
    loadOffersParams.expressions = defaultExpressions.expressions;
    loadOffersParams.domain = getDefaultDomainFilters();

    if (isMobileWidth && !loadOffersInMobile) {
      offersListContextFunctions.getOffersCount(
        loadOffersParams,
        filtersContextFunctions.setFiltersData,
      );
    } else {
      offersListContextFunctions.getOffersList(
        loadOffersParams,
        filtersContextFunctions.setFiltersData,
      );
    }
  };

  // FILTER FEATURES
  const onSort = sorting => {
    const loadOffersParams = getContextRequestParamsFromFilters({
      filters: offersListFilters.filters,
      sorting,
      selectedRegions,
      radius,
      filterSet: offersListFilters.filterSet,
    });

    loadOffers(loadOffersParams, true);
  };

  const onRegionChange = () => {
    const loadOffersParams = getContextRequestParamsFromFilters({
      filters: offersListFilters.filters,
      sorting,
      selectedRegions,
      radius,
      filterSet: offersListFilters.filterSet,
      endCursor,
    });

    if (isPopupShown) {
      regionWasChangedInFilterPopup = true;
    }
    loadOffers(loadOffersParams, true);
  };

  const onFetchMore = event => {
    event.preventDefault();

    const requestParamsFromFilters = getContextRequestParamsFromFilters({
      filters: offersListFilters.filters,
      sorting,
      selectedRegions,
      radius,
      filterSet: offersListFilters.filterSet,
    });

    const loadOffersParams = {
      after: endCursor,
      ...requestParamsFromFilters,
    };

    if (isWidgetMode) {
      fetchMore = true;
    }
    loadOffers(loadOffersParams, true);
  };

  // BROWSER URL FEATURE
  const setBrowserLocationPath = () => {
    const { history } = props;

    const url = generateUrlFromFilters(
      regions,
      selectedRegions,
      radius,
      offersListFilters.filters,
      sorting,
      endCursor && Number(endCursor) > 20 ? Number(endCursor) - 20 : null,
      history.location.search,
    );

    history.replace(url);
  };

  const getSeoData = () => {
    // Title: Продажа новых автомобилей Land Rover Range Rover Evoque в России/Москве, цены и наличие у дилеров
    // Description: Продажа новых автомобилей Land Rover Range Rover Evoque в России/Москве. Проверенные цены на авто, комплектации и актуальное наличие машин на складах официальных дилеров
    // H1: Продажа новых автомобилей Land Rover Range Rover Evoque в России/Москве, цены и наличие у официальных дилеров

    const { brand, model, version } = getSelectedFilterParams(
      offersListFilters.filters,
    );
    const carNameString = `${brand ? " " + brand : ""}${
      model ? " " + model : ""
    }${version && version.length === 1 ? " " + version[0] : ""}`;

    const region = getSelectedRegionName(regions, selectedRegions);

    return {
      title:
        `Продажа новых автомобилей${carNameString} в ${region || "России"}` +
        `, ${!isWidgetMode ? "ориентировочные " : ""}цены и наличие у дилеров`,
      description:
        `Продажа новых автомобилей${carNameString} в ${region || "России"}.` +
        ` ${
          !isWidgetMode ? "Ориентировочные " : "Проверенные "
        }цены на авто, комплектации и актуальное наличие машин на складах официальных дилеров`,
      h1:
        `Продажа новых автомобилей${
          !isWidgetMode ? carNameString : ""
        } в ${region || "России"}` +
        `, ${
          !isWidgetMode ? "ориентировочные " : ""
        }цены и наличие у официальных дилеров`,
    };
  };

  const onBackButtonClick = () => {
    offersListContextFunctions.setOffersResults(offersResults);
    filtersContextFunctions.changeState(
      { filters: offersListFilters.previousFilters },
      "offersList",
      { isPopupShown: false },
    );
    if (isWidgetMode) {
      prevFilterSet = "ALL";
    }
    scrollWindow(0);
    if (regionWasChangedInFilterPopup) {
      const loadOffersParams = getContextRequestParamsFromFilters({
        filters: offersListFilters.filters,
        sorting,
        selectedRegions,
        radius,
        filterSet: offersListFilters.filterSet,
      });

      loadOffers(loadOffersParams);
      regionWasChangedInFilterPopup = false;
    }
  };

  const { location, history } = props;
  const { addToCarsIdList, removeFromCarsIdList } = compareContextFunctions;

  const search = location.search.substr(1);
  const selectedFilters = offersListFilters.filters.filter(f => f.selected);
  let regionFilterMatters = 0;
  const selectedRegionsArray = [];
  let selectedRegionsNames = "";

  regions.forEach(region => {
    if (selectedRegions.some(item => item === region.id)) {
      regionFilterMatters = 1;
      selectedRegionsArray.push(region.resourcePath);
      selectedRegionsNames += `${selectedRegionsNames ? ", " : ""}${
        region.name
      }`;
    }
  });
  const selectedBrandPath = getSelectedFilterParams(offersListFilters.filters)
    .brandPath;
  const moreVariantsCount =
    !!endCursor && endCursor < results.totalCount
      ? results.totalCount < Number(endCursor) + 20
        ? results.totalCount - endCursor
        : 20
      : 0;

  const seoData = getSeoData();

  return (
    <div className="skeleton c-offers-page">
      <Seo description={seoData.description} title={seoData.title} />
      <Header
        onFilterHistoryClick={event => {
          if (widgetConfig && widgetConfig.filterHistoryBtnClickGoToMain) {
            event.preventDefault();
            history.push("/");
            return "stopExecution";
          }
        }}
        onBackButtonClick={() => {
          onBackButtonClick();
        }}
        isOffersList={true}
      />
      <div id="main">
        <div>
          <div className="s-top clearfix">
            <MobileFiltersLabel
              filters={offersListFilters.filters}
              filtersNumber={selectedFilters.length}
              filtersLocation="offersList"
              onClick={() => {
                offersResults = results;
              }}
            />

            <div className="s-left">
              <h1 className="s-main-header">{getLocalText(seoData.h1)}</h1>
              {results &&
                (results.offerCount > 0 ||
                  results.totalCount > 0 ||
                  results.dealerCount > 0) && (
                  <div className="s-search-information">
                    <Results
                      inline={true}
                      offerCount={results.offerCount}
                      totalCount={results.totalCount}
                      dealerCount={results.dealerCount}
                    />
                  </div>
                )}
            </div>

            <div className="s-right">
              <Sorting value={sorting} onChange={onSort} />
            </div>
          </div>

          <div className="s-offers-list clearfix">
            <div
              className={classNames("s-left", {
                "show-mobile-filters": isPopupShown,
              })}
            >
              {offers.length === 0 &&
              (status === REQUEST_STATUS.LOADING ||
                status === REQUEST_STATUS.NONE) ? (
                <Loading />
              ) : (
                <>
                  {(status === REQUEST_STATUS.LOADING ||
                    status === REQUEST_STATUS.NONE) && <Loading />}
                  {offers.length > 0 ? (
                    <div className="c-offers-list">
                      {offers.map((offer, index) => (
                        <div
                          key={offer.node.id}
                          className={classNames(
                            "offer_container",
                            (index + 1) % 20 !== 0 && "show-border",
                          )}
                        >
                          <Offer
                            search={search}
                            offer={offer.node}
                            region={
                              selectedRegionsArray.length === 1
                                ? selectedRegionsArray[0]
                                : ""
                            }
                            history={history}
                            carsIdList={carsIdList}
                            addToCarsIdList={addToCarsIdList}
                            removeFromCarsIdList={removeFromCarsIdList}
                          />
                          {(index + 1) % 20 === 0 &&
                            index + 1 !== offers.length && (
                              <div className="page-separator">
                                <span className="label">
                                  {(index + 1) / 20 + 1}
                                </span>
                              </div>
                            )}
                        </div>
                      ))}
                      {moreVariantsCount > 0 && (
                        <div className="s-show-more">
                          <LinkButton
                            url={generateUrlFromFilters(
                              regions,
                              selectedRegions,
                              radius,
                              offersListFilters.filters,
                              sorting,
                              endCursor,
                            )}
                            className="text-button"
                            text={getLocalTextWithNumbers(
                              "Показать еще ${number} ${changeableText}",
                              [
                                {
                                  id: "number",
                                  value: moreVariantsCount,
                                },
                                {
                                  id: "changeableText",
                                  value: declOfNum(moreVariantsCount, [
                                    "вариант",
                                    "варианта",
                                    "вариантов",
                                  ]),
                                },
                              ],
                            )}
                            onClick={event => onFetchMore(event)}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="zero-results">
                      По Вашему запросу ничего не найдено. Для нового
                      поиска&nbsp; Вы можете очистить фильтры или изменить
                      регион поиска.
                    </div>
                  )}
                </>
              )}
            </div>

            <div
              className={classNames("s-right", {
                "show-mobile-filters": isPopupShown,
              })}
              ref={ref => (filterRef = ref)}
            >
              <div className="s-wrapper">
                <Filters
                  filters={offersListFilters.filters}
                  selectedRegionPath={
                    selectedRegionsArray.length === 1
                      ? selectedRegionsArray[0]
                      : null
                  }
                  selectedBrandPath={selectedBrandPath}
                  onChange={loadOffers}
                  selectedFilters={selectedFilters}
                  results={results}
                  status={status}
                  showFilterSetButton={true}
                  showCleanButton={true}
                  filtersLocation="offersList"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

OffersListPage.fetchData = (dataContext, match, request) => {
  const regionsFromCookies = dataContext.regions || {};
  const { selectedRegions = [], radius = null } = regionsFromCookies;
  const location = {
    pathname: request.originalUrl.split("?")[0],
    search: request.originalUrl.split("?")[1],
  };
  const offersParams = getFilterRequestParamsFromLocation(
    location,
    [],
    selectedRegions,
    radius,
  );

  return new Promise((resolve, reject) => {
    getPopularAndSelectedRegions(
      offersParams.geoPath,
      offersParams.geo ? offersParams.geo.radius : null,
      selectedRegions,
    ).then(regions => {
      regions.selectedRegions = regions.selectedRegions || selectedRegions;
      regions.radius = regions.radius || radius;
      regions = {
        ...regionsFromCookies,
        ...regions,
      };
      offersParams.geo = Object.assign(
        { ids: regions.selectedRegions },
        regions.radius ? { radius: regions.radius } : {},
      );

      loadOffersList(offersParams)
        .then(result => {
          const filters = {
            offersList: getFiltersData(result.filters),
          };
          resolve({
            isDataContext: true,
            regions,
            filters,
            offersList: result,
            is404Error: !result || !result.offers || result.offers.length === 0,
          });
        })
        .catch(() => {
          reject();
        });
    });
  });
};

export const OffersListSSRLoad = OffersListPage.fetchData;
export default OffersListPage;
